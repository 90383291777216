import React, { useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';
//dont import this
import { auth } from './api/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, setUser } from './redux/slices/userSlice';
import Home from './pages/Home';
import Login from './pages/Login';
import Blog from './pages/Blog';
import Course from './pages/Course';
import NotFound from './pages/NotFound';
import FAQPage from './pages/FrequentlyAskQuestions';
import BlogPostPage from './components/BlogPostPage';
import PositionPage from './components/PositionPage';
import CareerCategory from './components/CareerCategory';
import ThankYouPage from './pages/ThankYouPage';
import CourseDetails from './components/CourseDetails';
import Checkout from './pages/Checkout';
import OrderSuccess from './pages/OrderSuccess/OrderSuccess';
import Portal from './pages/Portal';
import SignupOneOnOne from './pages/OneOnOne';
import ScrollToTop from './components/ScrollToTop';
import OrderCanceled from './pages/OrderCancel/OrderCancel';
import Navbar from './components/Navbar';
const allPositions = [
  {
    id: 'marketing',
    title: 'Marketing Team',
    description: "As a key member of our Marketing Team, you will develop and execute innovative campaigns that not only communicate the value of education but also inspire learners and educators around the globe. Your creative strategies will help expand our brand's reach and make a meaningful impact in the educational sector.",
    qualifications: "Currently pursuing or graduated with a Bachelor’s degree in Marketing, Communications, or a related field. Proven experience in digital marketing, campaign management, and content creation. Strong analytical skills and data-driven thinking are essential."
    // Other details...
  },
  {
    id: 'sales',
    title: 'Sales Team',
    description: "Join our dynamic Sales Team to play a crucial role in expanding our customer base and enhancing our market presence. Your efforts will directly contribute to connecting more educational institutions and learners with our transformative technology solutions, helping them achieve their learning goals efficiently.",
    qualifications: "Currently pursuing or graduated with a Bachelor's degree in Business, Marketing, or a related field. Proven track record in sales, particularly in the tech or educational sectors. Excellent communication and negotiation skills."
  },
  {
    id: 'engineering',
    title: 'Engineering Team',
    description: "Be part of our Engineering Team where you will contribute to building and refining the next generation of our learning platform. Your work will focus on creating robust, scalable solutions that enhance the learning experience for users worldwide, leveraging cutting-edge technologies to make education more accessible and effective.",
    qualifications: "Currently pursuing or graduated with a Bachelor's degree in Computer Science, Engineering, or a related field. Experience with modern programming languages and web development technologies. Strong problem-solving skills and a passion for technology innovation."
    // Other details...
  },
  {
    id: 'instructor',
    title: 'Instructor',
    description: "As an Instructor, you will guide learners through engaging, project-based learning experiences that incorporate the latest technologies. Your role will involve designing and executing innovative projects that not only teach technical skills but also foster creativity and problem-solving abilities, preparing learners for real-world challenges.",
    qualifications: "Currently pursuing or graduated with a degree in Computer Science, Engineering, Education, Instructional Design, or a relevant field. Strong organizational and communication skills, with an ability to motivate and inspire learners."
    // Other details...
  },
  // ... more positions
];


/**
 * The main application function that sets up the routing and authentication for the app.
 *
 * @return {JSX.Element} The JSX element representing the app.
 */
function App() {
  const dispatch = useDispatch();
  // const [isDiscountVisible, setIsDiscountVisible] = useState(true); 
  // const handleCloseDiscount = useCallback(() => {
  //   setIsDiscountVisible(false);
  // }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(setUser({
          email: user.email,
          uid: user.uid,
        }));
      } else {
        dispatch(logoutUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);



  return (
    <Router>
      <div className='mb-36'>
        <Navbar />
      </div>
      <ScrollToTop />
      {/*<div className="fixed top-10 right-0 z-50 p-0">
        <DiscountMessage />
      </div>*/}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/portal" element={
          <ProtectedRoute>
            <Portal />
          </ProtectedRoute>
        } />
        <Route path="/1on1" element={<SignupOneOnOne />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:postId" element={<BlogPostPage />} />
        <Route path="/FAQ" element={<FAQPage />} />
        <Route path="/courses" element={<Course />} />
        <Route path="/courses/:courseId" element={<CourseDetails />} />
        <Route path='/careers' element={<CareerCategory positions={allPositions}/>}  />
        <Route path="/careers/:positionId" element={<PositionPage positions={allPositions} />} />
        <Route path="/thankyouforjoiningourteam" element={<ThankYouPage />} />
        <Route path='/checkout' element={<Checkout/>} />
        <Route path='/order-success' element={<OrderSuccess/>} />
        <Route path='/order-canceled' element={<OrderCanceled/>} /> 
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

/**
 * A protected route that checks if the user is logged in before rendering the children.
 *
 * @param {object} children - The JSX elements to be rendered if the user is logged in.
 * @return {JSX.Element} The children if the user is logged in, otherwise a redirect to the login page.
 */
function ProtectedRoute({ children }) {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    // Redirect to the /login page with the current location state
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  return children;
}



export default App;
