import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import BlogTemplate from '../../components/BlogTemplate';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import './BlogPostPage.css';
import Footer from '../../components/Footer';
import { fetchSingleBlogPost, fetchBlogPosts } from '../../redux/slices/blogSlice';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

/**
 * A React functional component that renders a single blog post page.
 *
 * It fetches the blog post data from the Redux store, handles loading and error states,
 * and displays the post content along with featured posts.
 *
 * @return {JSX.Element} The JSX element representing the blog post page.
 */
const BlogPostPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { postId } = useParams();
    const post = useSelector(state => state.blog.posts.find(post => post.id === postId));
    const loading = useSelector(state => state.blog.loadingSinglePost);
    const posts = useSelector(state => state.blog.posts);
    const status = useSelector(state => state.blog.status);

    useEffect(() => {
        if (!post) {
          dispatch(fetchSingleBlogPost(postId));
        }
        if (status === 'idle') {
          dispatch(fetchBlogPosts({ numToFetch: 10 }));
        }
        logEvent(analytics, 'page_view', { page_title: 'Blog Post Page', post_id: postId });
    }, [postId, post, status, dispatch]);

    const goBack = () => {
        logEvent(analytics, 'button_click', { button_name: 'Go Back', post_id: postId });
        navigate(-1);
    };
    const featuredPosts = posts.filter(p => p.id !== postId).slice(0, 3);
    let content;
    if (loading === 'loading') {
        content = (
            <div className="loading-container text-webDarker">
                <FontAwesomeIcon icon={faSpinner} spin size="4x" />
            </div>
        );
    } else if (post) {
        content = <BlogTemplate {...post} featuredPosts={featuredPosts} />;
    } else {
        content = (
            <div className="not-found-container">
                <FontAwesomeIcon icon={faTimesCircle} className="not-found-icon" />
                <p className="not-found-message">Post not found.</p>
                <button onClick={goBack} className="go-back-button">
                <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </button>
            </div>
        );
    }

    return (
        <div className="app-container">
            <Helmet>
                <title>{post ? post.title : "Blog Post"}</title>
                <meta name="description" content={post ? post.content.substring(0, 150) : "Blog post content"} />
                <meta name="keywords" content={post ? post.tags.join(', ') : "blog, post, content, tech, EdTech, tips"} />
            </Helmet>

            <div className="content">
                {content}
            </div>
            <Footer />
        </div>
    );
};

export default React.memo(BlogPostPage);
