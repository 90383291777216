import React from 'react';

function WhyChooseUsSection() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-2 px-4 py-12 md:px-10 md:py-16 w-full">
      <section className="flex flex-col items-center md:items-center text-center md:text-left md: ml-3">
        <h2 className="font-normal text-3xl md:text-4xl mb-4">Why Choose Albuddy</h2>
        <p className="text-gray-600 max-w-full md:max-w-md md: text-center">
          We're not just another tutoring platform. We provide personalized mentorship, real-world project experience, and access to industry guest speakers. Our goal is to empower students to become future innovators.
        </p>
      </section>
      <section className="flex flex-col justify-center bg-gradient-to-r md:bg-gradient-to-r from-white to-[#dbe8fc] rounded-3xl p-10 md:p-12">
        <div className="space-y-12">
          <div className="flex items-start">
            <h4 className="text-7xl md:text-[110px] text-webDarker">1</h4>
            <div className="ml-12">
              <h3 className="text-2xl md:text-3xl font-normal text-black">Personalized Mentorship</h3>
              <p className="text-base md:text-lg text-gray-600">
                Tailored guidance to help each student achieve their unique goals.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <h4 className="text-7xl md:text-[110px] text-webDarker">2</h4>
            <div className="ml-12">
              <h3 className="text-2xl md:text-3xl font-normal text-black">Real-World Projects</h3>
              <p className="text-base md:text-lg text-gray-600">
                Hands-on learning with projects that mimic real-world challenges.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <h4 className="text-7xl md:text-[110px] text-webDarker">3</h4>
            <div className="ml-12">
              <h3 className="text-2xl md:text-3xl font-normal text-black">Industry Guest Speakers</h3>
              <p className="text-base md:text-lg text-gray-600">
                Insights and inspiration from professionals at the forefront of their fields.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyChooseUsSection;