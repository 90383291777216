// src/components/ServicesSection/ServicesSection.js
import React from 'react';
import './ServiceSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faNetworkWired, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

/**
 * A React component that renders a section of services, including programming fundamentals, software engineering principles, and project-based learning.
 *
 * @return {JSX.Element} A JSX element representing the services section.
 */
function ServicesSection() {
  return (
    <section className="services-section">
      <div className="service">
        <FontAwesomeIcon icon={faLaptopCode} size="3x" className="service-icon" />
        <h2>Programming Fundamentals</h2>
        <p>Grasp core concepts and apply them across languages from Python to Java, opening doors to diverse coding projects.</p>
      </div>
      <div className="service">
        <FontAwesomeIcon icon={faNetworkWired} size="3x" className="service-icon" />
        <h2>Software Engineering Principles</h2>
        <p>Learn to design and develop scalable systems using best practices and gain insights into the software development lifecycle.</p>
      </div>
      <div className="service">
        <FontAwesomeIcon icon={faProjectDiagram} size="3x" className="service-icon" />
        <h2>Project-Based Learning</h2>
        <p id='whyUs'>Engage with real-world projects from web apps to AI — powered by direct guidance from our expert team.</p>
      </div>
    </section>
  );
}

export default ServicesSection;
